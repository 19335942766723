import Enum from './enum'

/**
 * 最新リビジョンフラグ
 */
class IsLatestRevision extends Enum {
    static LATEST = 1; // 最新
    static PAST = 0; // 過去

    static values() {
        return {
            [this.LATEST]: '最新',
            [this.PAST]: '過去',
        }
    }
}

export default IsLatestRevision;

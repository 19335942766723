<template>
    <page-title
        icon="bi-pencil-square"
        title="製品編集履歴"
    >
        <button-back
            text="製品情報へ"
            :to="{name: 'ProductGroupView', params: {id: product_group_id}}"
        ></button-back>
    </page-title>

    <inline-loader v-if="loading || product_group === null"></inline-loader>
    <template v-else>
        <section class="section">
            <div class="d-flex justify-content-between align-items-center">
                <h2>
                    <span class="badge bg-primary">{{ product_group.product_group_code }}</span> {{ product_group.product_group_name }}
                </h2>
                <div>
                    <!-- 下書きのみの場合 リビジョン詳細へ -->
                    <template v-if="product_group.published_status === PublishedStatus.DRAFT">
                        <button-go-view
                            :to="{name: 'ProductGroupRevisionView', params: {id: product_group_id, revision: 1}}"
                            optional_class="btn-primary me-2"
                        >
                        </button-go-view>
                    </template>

                    <!-- 一度でも公開の場合 公開詳細へ -->
                    <button-go-view
                        v-else
                        :to="{name: 'ProductGroupView', params: {id: product_group_id}}"
                        optional_class="btn-primary me-2"
                    >
                    </button-go-view>

                    <button-go-update
                        v-if="$store.getters['auth/canEditAny']()"
                        :to="{name: 'ProductGroupEdit', params: {id: product_group_id}}"
                    >
                    </button-go-update>
                </div>
            </div>
            <div>
                <small class="fw-bold me-3">最終公開日:</small>
                <small class="me-3">{{ product_group.latest_published_date }}</small>
                <div>
                    <small class="fw-bold me-3">最終更新日:</small>
                    <small class="me-4">{{ product_group.updated_date }}</small>
                    <small class="fw-bold me-3">最終更新者:</small>
                    <small class="me-3">{{ product_group.updated_user_name }}</small>
                </div>
            </div>
        </section>

        <section class="section">
            <template v-for="product_group_revision in product_group_revisions" :key="product_group_revision">
                <div class="card mb-5">
                    <div class="card-header bg-secondary" style="--bs-bg-opacity: .5;">
                        <div class="d-flex justify-content-between align-items-center">
                            <h4 class="d-flex mb-0">
                                リビジョン <span class="font-weight-bold">{{ product_group_revision.revision }}</span>
                                <span
                                    class="badge bg-success fs-6 ms-3"
                                    :class="{'bg-danger': product_group_revision.revision_status === RevisionStatus.PENDING, 'bg-success': product_group_revision.revision_status === RevisionStatus.PUBLISHED}"
                                    v-if="product_group_revision.revision_status !== RevisionStatus.PAST"
                                >{{ product_group_revision.revision_status_display }}</span>
                            </h4>
                            <div>
                                <button-go-view
                                    v-if="product_group_revision.revision_status === RevisionStatus.PUBLISHED"
                                    text="表示"
                                    :to="{name: 'ProductGroupView', params: {id: product_group_id}}"
                                    class="btn-light ms-1"
                                ></button-go-view>
                                <button-go-view
                                    v-else
                                    text="表示"
                                    :to="{name: 'ProductGroupRevisionView', params: {id: product_group_id, revision: product_group_revision.revision}}"
                                    class="btn-light ms-1"
                                ></button-go-view>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <!-- 変更理由 -->
                        <template  v-if="product_group_revision.revision_comment">
                            <h5>変更理由</h5>
                            <p>{{ product_group_revision.revision_comment }}</p>
                        </template>

                        <template v-for="history in product_group_revision.histories" :key="history">
                            <template  v-if="history.details.length">
                                <h5 class="text-success">
                                    <i class="bi" :class="history.edit_type_icon"></i>
                                    <span class="ms-1 fs-6">{{ history.edit_datetime_display }}</span><span class="ms-3 fs-6">{{ history.user.user_name }}</span>
                                </h5>
                                <table class="table table-history border">
                                    <tbody>
                                        <tr v-for="detail in history.details" :key="detail">
                                            <td class="text-dark bg-light" style="width: 10%;">
                                                {{ detail.target_type_display }}:
                                            </td>
                                            <td class="text-dark bg-light" style="width: 17%;">{{ detail.target_code }}</td>
                                            <td class="border-start bg-light" style="width: 18%;">{{ detail.target_label }}</td>
                                            <td class="fw-bold" style="width: 27%;">{{ detail.before_value_display }}</td>
                                            <td class="text-secondary text-center">→</td>
                                            <td class="fw-bold" style="width: 27%;">{{ detail.after_value_display}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </template>
                        </template>
                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <span class="text-muted" v-if="product_group_revision.revision_status === RevisionStatus.PENDING">未公開</span>
                                <span class="text-muted" v-else>{{ product_group_revision.published_datetime_display }} 公開</span>
                            </div>
                            <div>
                                <button-exec
                                    v-if="$store.getters['auth/canEditBasic']() && product_group_revision.revision_status === RevisionStatus.PENDING"
                                    text="このリビジョンを公開"
                                    icon=""
                                    size="btn-sm"
                                    color="btn-outline-primary"
                                    @click="$refs.confirm_publish.show(); setTargetRevision(product_group_revision);"
                                ></button-exec>
                                <button-exec
                                    v-if="$store.getters['auth/canEditBasic']() && product_group_revision.revision_status === RevisionStatus.PAST"
                                    text="このリビジョンに戻す"
                                    icon=""
                                    size="btn-sm"
                                    color="btn-outline-primary"
                                    @click="$refs.confirm_rollback.show(); setTargetRevision(product_group_revision);"
                                ></button-exec>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <paginator :meta="paginator" @move="fetchProductGroupRevisions"></paginator>
        </section>
    </template>

    <confirm-dialog ref="confirm_publish" @ok="publishRevision()" @cancel="unsetTargetRevision()">
        <p>このリビジョン（Revision: {{ this.target_product_group_revision.revision }}）を公開してもよろしいですか？</p>
    </confirm-dialog>

    <confirm-dialog ref="confirm_rollback" @ok="rollbackRevision()" @cancel="unsetTargetRevision()">
        <p>このリビジョン（Revision: {{ this.target_product_group_revision.revision }}）に戻してもよろしいですか？</p>
    </confirm-dialog>
</template>

<script>
import ProductGroup from '@/models/entities/product-group';
import ProductGroupRevision from '@/models/entities/product-group-revision';
import PublishedStatus from '@/models/enums/published-status';
import IsLatestRevision from '@/models/enums/is-latest-revision';
import RevisionStatus from '@/models/enums/revision-status';
import PageTitle from '@/components/PageTitle.vue';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import ButtonExec from '@/components/buttons/ButtonExec';
import ButtonGoUpdate from '@/components/buttons/ButtonGoUpdate';
import ButtonGoView from '@/components/buttons/ButtonGoView';
import ButtonBack from '@/components/buttons/ButtonBack';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';
import Paginator from '@/components/tools/Paginator';

export default {
    name: 'ProductGroupHistory',
    components: {
        InlineLoader,
        PageTitle,
        ButtonExec,
        ButtonGoUpdate,
        ButtonGoView,
        ButtonBack,
        ConfirmDialog,
        Paginator,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage',
    ],
    data() {
        return {
            //部分ローダー
            loading: 0,

            //現在の製品グループID
            product_group_id: parseInt(this.$route.params.id, 10),

            //製品グループ
            product_group: parseInt(this.$route.params.revision, 10) ? new ProductGroupRevision() : new ProductGroup(),

            //製品グループリビジョン
            product_group_revisions: [],

            paginator: {},

            //アクションターゲット
            target_product_group_revision: null,

            //enums
            PublishedStatus: PublishedStatus,
            RevisionStatus: RevisionStatus,
            IsLatestRevision: IsLatestRevision,
        }
    },
    mounted() {
        this.fetchProductGroup();
        this.fetchProductGroupRevisions();

    },
    computed: {
    },
    methods: {
        //製品グループマスタを取得
        fetchProductGroup() {
            this.loading++;

            //リセット
            this.product_group = parseInt(this.$route.params.revision, 10) ? new ProductGroupRevision() : new ProductGroup();

            this.$http.get(`/product-group/${this.product_group_id}`)
            .then(response => {
                this.product_group = new ProductGroup(response.data);

            })
            .finally(() => {
                this.loading--;
            });
        },
        //製品グループリビジョンを取得
        fetchProductGroupRevisions(page = 1) {
            this.loading++;

            this.$http.get(`/product-group/${this.product_group_id}/revision`, {
                params: {
                    page: page,
                }
            })
            .then(response => {
                this.product_group_revisions = [];
                for (let row of response.data.data) {
                    this.product_group_revisions.push(new ProductGroupRevision(row));
                }
                //ページネーション用
                this.paginator = response.data.meta;
            })
            .finally(() => {
                this.loading--;
            });
        },
        publishRevision() {
            this.loading++;
            if (null === this.target_product_group_revision) {
                return;
            }
            this.$http.post(`/product-group/${this.product_group_id}/revision/${this.target_product_group_revision.product_group_revision_id}/publish`)
            .then(() => {
                this.fetchProductGroupRevisions();
            })
            .finally(() => {
                this.loading--;
            });
        },
        rollbackRevision() {
            this.loading++;
            if (null === this.target_product_group_revision) {
                return;
            }
            this.$http.post(`/product-group/${this.product_group_id}/revision/${this.target_product_group_revision.product_group_revision_id}/revert`)
            .then(() => {
                this.fetchProductGroupRevisions();
            })
            .finally(() => {
                this.loading--;
            });
        },
        setTargetRevision(product_group_revision) {
            this.target_product_group_revision = product_group_revision;
        },
        unsetTargetRevision() {
            this.target_product_group_revision = null;
        },
    }
}
</script>

<style scoped>
    td {white-space: pre-wrap; word-break: break-all; max-width: 30%;}
    .rotate:before {transform: scale(-1, 1);}
</style>
